import { Menu, Popover, SimpleGrid, Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData, useGetSelectDataUndefined } from 'generation/centerFrontedEngine'
import { trpc } from 'utils/trpc'

export function WBotComponentLinkSync({
  modelID,
  selectID,
  boxID,
}: {
  modelID?: string
  selectID?: 'WhatsAppBotComponent1'
  boxID: string
}) {
  const wBotComponent = useGetSelectDataUndefined({ modelID, selectID })
  const wBotLink = useGetSelectDataUndefined({ modelID: wBotComponent?.link.id, selectID: wBotComponent?.link.selectID })

  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const wBot = useGetSelectDataUndefined({ modelID: site.whatsAppBot?.id, selectID: site.whatsAppBot?.selectID })

  const connectLinkToBox = trpc.useMutation('user.wBot.connectLinkToBox', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const disconnectLinkToBox = trpc.useMutation('user.wBot.disconnectLinkToBox', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  return wBot ? (
    <>
      <Menu.Label>Вбот {wBot.connected ? '' : '(Не связан)'}</Menu.Label>
      {wBot.phone ? (
        <>
          {wBotLink ? (
            <>
              <Text>Выбрана ссылка {wBotLink.text}</Text>
              <Text
                color="red"
                onClick={() => {
                  if (wBotComponent) {
                    disconnectLinkToBox.mutate({
                      wBotComponentID: wBotComponent.id,
                    })
                  }
                }}
              >
                открепить
              </Text>
            </>
          ) : (
            <>
              <Popover withinPortal position="right-start" width={'auto'} shadow="md">
                <Popover.Target>
                  <Menu.Item>{'Прикрепить к вБоту'}</Menu.Item>
                </Popover.Target>
                <Popover.Dropdown
                  css={{
                    padding: '8px 12px',
                    borderRadius: 2,
                  }}
                >
                  <SimpleGrid>
                    <Text color="green">Прикрепить к ссылке</Text>
                    {wBot.links.map(link => (
                      <WBotLink
                        onClick={({ linkID }) => {
                          connectLinkToBox.mutate({ boxID, wBotLinkID: linkID })
                        }}
                        key={link.id}
                        modelID={link.id}
                        selectID={link.selectID}
                      />
                    ))}
                  </SimpleGrid>
                </Popover.Dropdown>
              </Popover>
            </>
          )}
        </>
      ) : (
        <>
          <Text>Нету телефона</Text>
        </>
      )}
    </>
  ) : null
}

function WBotLink({
  modelID,
  selectID,
  onClick,
}: {
  modelID: string
  selectID: 'WhatsAppBotLink1'
  onClick: ({}: { linkID: string }) => void
}) {
  const link = useGetSelectData({ modelID, selectID })

  return (
    <Text
      onClick={() => {
        onClick({ linkID: link.id })
      }}
    >
      - {link.text}
    </Text>
  )
}
