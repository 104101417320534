
  import type {AllSelectTypes} from './centerFrontedTypesEngine'

import { createContext, useContext, useEffect, useState } from 'react'



import {getDataBasedOnUniqueSelectAtSite, frontendCallbackDataCenterSite } from './synchronFrontendAtSite'

  export type DataSelectContext = {
    type: "Site"
  }
  export const MyDataSelectContext = createContext<DataSelectContext>({
    type: "Site",
  })
  export const useBoxBlockContext = () => useContext(MyDataSelectContext)
  
  export function useGetSelectData<SelectID extends keyof AllSelectTypes>({
    selectID,
    modelID,
  }: {
    selectID: SelectID
    modelID: string
  }) {
    const context = useBoxBlockContext()

    const data = 

    context.type == 'Site' && (selectID == "Site1" 
          
          ||selectID == "UserSelect1" 
          
          ||selectID == "TelegramChannel1" 
          
          ||selectID == "TelegramGift1" 
          
          ||selectID == "Image1" 
          
          ||selectID == "PageOnlyWithFields" 
          
          ||selectID == "Page1" 
          
          ||selectID == "PageForLink" 
          
          ||selectID == "Target1" 
          
          ||selectID == "TelegramPersonalBot1" 
          
          ||selectID == "PaidPlace1" 
          
          ||selectID == "PaidPlaceOutcomeBalance1" 
          
          ||selectID == "TelegramUser1" 
          
          ||selectID == "Domain1" 
          
          ||selectID == "SectionsPinned1" 
          
          ||selectID == "SectionsPinnedID" 
          
          ||selectID == "Sections1" 
          
          ||selectID == "Section1" 
          
          ||selectID == "SectionID" 
          
          ||selectID == "PopupWithName" 
          
          ||selectID == "PopupID" 
          
          ||selectID == "Popup1" 
          
          ||selectID == "BoxBlock1" 
          
          ||selectID == "Box1" 
          
          ||selectID == "ResponsiveBoxSetting1" 
          
          ||selectID == "BoxSetting1" 
          
          ||selectID == "BoxBackground1" 
          
          ||selectID == "Color1" 
          
          ||selectID == "BoxBorder1" 
          
          ||selectID == "BoxShadow1" 
          
          ||selectID == "Link1" 
          
          ||selectID == "TextComponent1" 
          
          ||selectID == "TextSettings1" 
          
          ||selectID == "FontWeight1" 
          
          ||selectID == "ImageComponent1" 
          
          ||selectID == "FormComponent1" 
          
          ||selectID == "Form1" 
          
          ||selectID == "ExtraInput1" 
          
          ||selectID == "QuizComponent1" 
          
          ||selectID == "Quiz1" 
          
          ||selectID == "QuizStep1" 
          
          ||selectID == "QuizCheckboxStep1" 
          
          ||selectID == "QuizCheckboxStepItem1" 
          
          ||selectID == "QuizRadioStep1" 
          
          ||selectID == "QuizRadioStepItem1" 
          
          ||selectID == "QuizFieldInputStep1" 
          
          ||selectID == "VideoComponent1" 
          
          ||selectID == "FaqComponent1" 
          
          ||selectID == "FaqComponentItem1" 
          
          ||selectID == "MapComponent1" 
          
          ||selectID == "MapComponentPoint1" 
          
          ||selectID == "CodeComponent1" 
          
          ||selectID == "WhatsAppBotComponent1" 
          
          ||selectID == "WhatsAppBotLink1" 
          
          ||selectID == "TableColumnSimple" 
          
          ||selectID == "TableColumnAtBoxAtSchema" 
          
          ||selectID == "TableColumnAtBox" 
          
          ||selectID == "TableColumn1" 
          
          ||selectID == "TableAtBox" 
          
          ||selectID == "TableWithID" 
          
          ||selectID == "Table1" 
          
          ||selectID == "TableConnectorAtBox" 
          
          ||selectID == "TableConnectorWithID" 
          
          ||selectID == "TableConnector1" 
          
          ||selectID == "TableDataAtBox" 
          
          ||selectID == "TableDataPinnedToPage" 
          
          ||selectID == "TableData1" 
          
          ||selectID == "TableRowAtBox" 
          
          ||selectID == "TableDataRowPinnedToPage" 
          
          ||selectID == "TableDataRow1" 
          
          ||selectID == "TableRowItemAtBox" 
          
          ||selectID == "TableDataRowItem1" 
          
          ||selectID == "BlockCategory1" 
          
          ||selectID == "Lead1" 
          
          ||selectID == "GroupFont1" 
          
          ||selectID == "WhatsAppBot1" 
          
          ) ? 
          getDataBasedOnUniqueSelectAtSite({ modelID, selectID }) as AllSelectTypes[SelectID]

           : undefined
  
      if (!data) throw new Error('data not found in useGetSelectData')
    return data
  }

  export function useGetSelectDataUndefined<SelectID extends keyof AllSelectTypes>({
    selectID,
    modelID,
  }: {
    selectID?: SelectID
    modelID?: string
  }) {
    const context = useBoxBlockContext()

    const data =

    context.type == 'Site'  ? getDataBasedOnUniqueSelectAtSite({ modelID, selectID }) as AllSelectTypes[SelectID]

           : undefined

    return data
  }
