import { Button, Container, CopyButton, Divider, Group, Paper, SimpleGrid, Text, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { IconCirclePlus, IconCopy, IconExternalLink, IconMailFast, IconTarget, IconTrash, IconX } from '@tabler/icons'
import { AccentButton } from 'components/AccentButton/AccentButton'
import { GrayButton } from 'components/GrayButton/GrayButton'
import QRCode from 'qrcode'
import { ChooseTarget } from 'syncComponents/ChooseTarget/ChooseTarget'

import { cabinetColors, cabinetSizes } from 'utils/cabinetTheme'

import { SyncSiteContextProvider, useSyncSiteContext } from 'contexts/SiteSyncContextProvider'
import { useGetSelectData, useGetSelectDataUndefined } from 'generation/centerFrontedEngine'
import Head from 'next/head'
import React, { useEffect, useRef, useState } from 'react'
import { SiteMenu } from 'syncComponents/SiteMenu/SiteMenu'

import { TargetSync } from 'syncComponents/TargetSync/TargetSync'
import { TextInputWithUpdate } from 'syncComponents/TextInputWithUpdate/TextInputWithUpdate'
import { ConnectToSiteNotifications } from 'utils/telegramBot'

import { getPathToFile } from 'utils/getPathToFile'
import { trpc } from 'utils/trpc'

import { useMainStyles } from 'pages/panel/sites/[siteId]/pages/[pageId]'
import { IntegrationsMenu } from 'syncComponents/IntegrationsMenu/IntegrationsMenu'
import { vinglaServiceBotUserName } from 'utils/telegramGiftCons'
import { IsLoggedUserWrap } from 'wrappers/IsLoggedUserWrap'

import { Favicon } from 'utils/Favicon'

export default function () {
  const { classes } = useMainStyles()
  return (
    <>
      <Head>
        <title>Интеграции</title>

        <Favicon />
      </Head>
      <IsLoggedUserWrap>
        <div
          css={{
            width: '100%',
            height: '100%',
            overflowY: 'auto',
          }}
          className={classes.hideScrollbar}
        >
          <PageSyncMemo />
        </div>
      </IsLoggedUserWrap>
    </>
  )
}

const PageSyncMemo = React.memo(
  () => {
    return (
      <SyncSiteContextProvider>
        <PageSync />
      </SyncSiteContextProvider>
    )
  },
  (prevProps, nextProps) => {
    return true
  }
)

export function PageSync() {
  const { siteID } = useSyncSiteContext()

  return (
    <>
      <SiteMenu activeTab="intergrations">
        <SiteIntergrationsSyncMemo />
      </SiteMenu>
    </>
  )
}

export const SiteIntergrationsSyncMemo = React.memo(SiteIntergrationsSync, () => true)

function SiteIntergrationsSync({ onClose }: { onClose?: () => void }) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const siteDomain = useGetSelectDataUndefined({ modelID: site.domain?.id, selectID: site.domain?.selectID })
  const paidPlace = useGetSelectData({ modelID: site.paidPlace?.id, selectID: site.paidPlace?.selectID })
  const user = useGetSelectData({ modelID: site.userId, selectID: 'UserSelect1' })

  const updateFieldSite = trpc.useMutation('user.site.updateFieldSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })
  const cacheSite = trpc.useMutation('user.site.cacheSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })

  const updateFaviconSite = trpc.useMutation('user.site.updateFaviconSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })
  const updateTemplatePictureSite = trpc.useMutation('user.site.updateTemplatePictureSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })

  const updateDomainSite = trpc.useMutation('user.domain.updateDomainSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {
      setDomainsOpened(false)
    },
  })

  const createTarget = trpc.useMutation('user.yandexMetrika.createTarget', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteTarget = trpc.useMutation('user.yandexMetrika.deleteTarget', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [imagesOpened, setImagesOpened] = useState(false)
  const [domainsOpened, setDomainsOpened] = useState(false)

  const moveSiteToUser = trpc.useMutation('user.moveSiteToUser', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const moveSiteToUserForm = useForm({
    initialValues: {
      userLogin: '',
    },
  })
  const createTargetForm = useForm({
    initialValues: {
      name: '',
    },
  })

  const [imagesTemplateOpened, setImagesTemplateOpened] = useState(false)

  const [leadEmail, setLeadEmail] = useState(site.leadEmail ? site.leadEmail : '')

  useEffect(() => {
    setLeadEmail(site.leadEmail ? site.leadEmail : '')
  }, [site.leadEmail])

  const setTelegramNotificationsToSite = trpc.useMutation('user.setTelegramNotificationsToSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const setTelegramNotificationCodeToSite = trpc.useMutation('user.setTelegramNotificationCodeToSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const pinnTelegramUser = useForm({
    initialValues: {
      userName: '',
    },
  })

  const connect_to_site_notifications: ConnectToSiteNotifications = 'connect_to_site_notifications'

  const linkToBot = `https://t.me/${vinglaServiceBotUserName}${
    site.telegramSiteNotificationConnectCode
      ? `?start=${connect_to_site_notifications}${site.telegramSiteNotificationConnectCode}`
      : ''
  }`

  return (
    <IntegrationsMenu activeTab={'metrika'}>
      <>
        <Container>
          <Paper p={'lg'}>
            <SimpleGrid>
              <SimpleGrid spacing={12} cols={1}>
                <YanderMetrikaLogo />

                <TextInputWithUpdate
                  value={`${site.yandexMetrikaId}`}
                  onChange={value => {
                    updateFieldSite.mutate({
                      siteID,
                      yandexMetrikaId: value,
                    })
                  }}
                  placeholder={'metrika id'}
                  label={'Номер/id яндекс метрики'}
                />

                <TextInputWithUpdate
                  value={`${site.metrikaApiKey}`}
                  onChange={value => {
                    updateFieldSite.mutate({
                      siteID,
                      metrikaApiKey: value,
                    })
                  }}
                  placeholder={''}
                  label={'Ключ api яндекс метрики'}
                />
              </SimpleGrid>

              <SimpleGrid
                py="md"
                css={{
                  background: cabinetColors.background,
                  borderRadius: cabinetSizes.radius,
                  border: `1px solid ${cabinetColors.border}`,
                }}
                spacing={0}
              >
                <Group px="md">
                  <SimpleGrid spacing={0} cols={1}>
                    <form
                      onSubmit={createTargetForm.onSubmit(async values => {
                        await createTarget.mutateAsync({
                          name: values.name,
                          siteID: site.id,
                        })
                        createTargetForm.setFieldValue('name', '')
                      })}
                    >
                      <Group css={{ alignItems: 'flex-end' }}>
                        <TextInput
                          required
                          placeholder={'Название цели'}
                          label={'Введите название цели'}
                          {...createTargetForm.getInputProps('name')}
                        />

                        <AccentButton leftIcon={<IconCirclePlus />} type="submit" loading={createTarget.isLoading}>
                          Создать цель
                        </AccentButton>
                      </Group>
                    </form>
                  </SimpleGrid>
                </Group>

                {site.targets.length ? (
                  <>
                    <Divider my="md" />
                    <SimpleGrid px="md" spacing={12} cols={1}>
                      <Title pl="xs" order={3}>
                        Цели
                      </Title>

                      {site.targets.length ? (
                        <SimpleGrid spacing={8} cols={1}>
                          {site.targets.map(target => (
                            <Paper
                              key={target.id}
                              css={{
                                // cursor: 'pointer',
                                alignItems: 'center',
                                alignContent: 'center',
                                border: `1px solid ${cabinetColors.border}`,
                              }}
                              p="sm"
                            >
                              <Group
                                position="apart"
                                css={{
                                  // cursor: 'pointer',
                                  alignItems: 'center',
                                  alignContent: 'center',
                                }}
                              >
                                <Group
                                  css={{
                                    // cursor: 'pointer',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                  }}
                                >
                                  <IconTarget color={cabinetColors.black} />
                                  <Text>
                                    <TargetSync key={target.id} modelID={target.id} selectID={target.selectID} />
                                  </Text>
                                </Group>
                                <GrayButton
                                  onClick={() => {
                                    deleteTarget.mutate({
                                      targetID: target.id,
                                    })
                                  }}
                                  leftIcon={<IconTrash color="red" />}
                                >
                                  <Text color="red">удалить</Text>
                                </GrayButton>
                              </Group>
                            </Paper>
                          ))}
                        </SimpleGrid>
                      ) : null}
                    </SimpleGrid>
                  </>
                ) : null}
              </SimpleGrid>
            </SimpleGrid>
          </Paper>
        </Container>
      </>
    </IntegrationsMenu>
  )
}

export function MailNotifications() {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const siteDomain = useGetSelectDataUndefined({ modelID: site.domain?.id, selectID: site.domain?.selectID })
  const paidPlace = useGetSelectData({ modelID: site.paidPlace?.id, selectID: site.paidPlace?.selectID })
  const user = useGetSelectData({ modelID: site.userId, selectID: 'UserSelect1' })

  const updateFieldSite = trpc.useMutation('user.site.updateFieldSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })
  const cacheSite = trpc.useMutation('user.site.cacheSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })

  const updateFaviconSite = trpc.useMutation('user.site.updateFaviconSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })
  const updateTemplatePictureSite = trpc.useMutation('user.site.updateTemplatePictureSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })

  const updateDomainSite = trpc.useMutation('user.domain.updateDomainSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {
      setDomainsOpened(false)
    },
  })

  const createTarget = trpc.useMutation('user.yandexMetrika.createTarget', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteTarget = trpc.useMutation('user.yandexMetrika.deleteTarget', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [imagesOpened, setImagesOpened] = useState(false)
  const [domainsOpened, setDomainsOpened] = useState(false)

  const moveSiteToUser = trpc.useMutation('user.moveSiteToUser', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const moveSiteToUserForm = useForm({
    initialValues: {
      userLogin: '',
    },
  })
  const createTargetForm = useForm({
    initialValues: {
      name: '',
    },
  })

  const [imagesTemplateOpened, setImagesTemplateOpened] = useState(false)

  const [leadEmail, setLeadEmail] = useState(site.leadEmail ? site.leadEmail : '')

  useEffect(() => {
    setLeadEmail(site.leadEmail ? site.leadEmail : '')
  }, [site.leadEmail])

  const setTelegramNotificationsToSite = trpc.useMutation('user.setTelegramNotificationsToSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const setTelegramNotificationCodeToSite = trpc.useMutation('user.setTelegramNotificationCodeToSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const pinnTelegramUser = useForm({
    initialValues: {
      userName: '',
    },
  })

  return (
    <>
      <Paper p="lg">
        <form
          onSubmit={event => {
            event.preventDefault()
            updateFieldSite.mutate({
              siteID: site.id,
              leadEmail: leadEmail,
            })
          }}
        >
          <SimpleGrid spacing={12}>
            <Title order={3}>Уведомления по почте</Title>
            <Group
              css={{
                alignItems: 'flex-end',
              }}
            >
              <TextInput
                type="email"
                placeholder="Введите почту"
                value={leadEmail}
                onChange={event => {
                  setLeadEmail(event.target.value)
                }}
              />
              <AccentButton leftIcon={<IconMailFast />} loading={updateFieldSite.isLoading} type="submit">
                {leadEmail ? 'Обновить почту' : 'Установить почту'}
              </AccentButton>
            </Group>
          </SimpleGrid>
        </form>
      </Paper>
    </>
  )
}
export function TelegramNotifications() {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const siteDomain = useGetSelectDataUndefined({ modelID: site.domain?.id, selectID: site.domain?.selectID })
  const paidPlace = useGetSelectData({ modelID: site.paidPlace?.id, selectID: site.paidPlace?.selectID })
  const user = useGetSelectData({ modelID: site.userId, selectID: 'UserSelect1' })

  const updateFieldSite = trpc.useMutation('user.site.updateFieldSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })
  const cacheSite = trpc.useMutation('user.site.cacheSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })

  const updateFaviconSite = trpc.useMutation('user.site.updateFaviconSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })
  const updateTemplatePictureSite = trpc.useMutation('user.site.updateTemplatePictureSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })

  const updateDomainSite = trpc.useMutation('user.domain.updateDomainSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {
      setDomainsOpened(false)
    },
  })

  const createTarget = trpc.useMutation('user.yandexMetrika.createTarget', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteTarget = trpc.useMutation('user.yandexMetrika.deleteTarget', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [imagesOpened, setImagesOpened] = useState(false)
  const [domainsOpened, setDomainsOpened] = useState(false)

  const moveSiteToUser = trpc.useMutation('user.moveSiteToUser', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const moveSiteToUserForm = useForm({
    initialValues: {
      userLogin: '',
    },
  })
  const createTargetForm = useForm({
    initialValues: {
      name: '',
    },
  })

  const [imagesTemplateOpened, setImagesTemplateOpened] = useState(false)

  const [leadEmail, setLeadEmail] = useState(site.leadEmail ? site.leadEmail : '')

  useEffect(() => {
    setLeadEmail(site.leadEmail ? site.leadEmail : '')
  }, [site.leadEmail])

  const setTelegramNotificationsToSite = trpc.useMutation('user.setTelegramNotificationsToSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const setTelegramNotificationCodeToSite = trpc.useMutation('user.setTelegramNotificationCodeToSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const pinnTelegramUser = useForm({
    initialValues: {
      userName: '',
    },
  })

  const connect_to_site_notifications: ConnectToSiteNotifications = 'connect_to_site_notifications'

  const linkToBot = `https://t.me/${vinglaServiceBotUserName}${
    site.telegramSiteNotificationConnectCode
      ? `?start=${connect_to_site_notifications}${site.telegramSiteNotificationConnectCode}`
      : ''
  }`
  return (
    <>
      <Paper py="md">
        <SimpleGrid spacing={0}>
          <Group px="lg">
            <SimpleGrid>
              <Group position="apart">
                <Title order={3}>Уведомления в телеграмм</Title>
                {!site.telegramSiteNotificationConnectCode ? (
                  <AccentButton
                    onClick={() => {
                      setTelegramNotificationCodeToSite.mutate({
                        siteID: site.id,
                      })
                    }}
                    loading={setTelegramNotificationCodeToSite.isLoading}
                  >
                    сгенерировать ссылку для подключения
                  </AccentButton>
                ) : null}
              </Group>

              {site.telegramSiteNotificationConnectCode ? (
                <Group>
                  <CopyButton value={linkToBot}>
                    {({ copied, copy }) => (
                      <GrayButton leftIcon={<IconCopy />} white={copied} onClick={copy}>
                        {copied ? 'Ссылка скопирована ' : 'Скопировать ссылку на подключение'}
                      </GrayButton>
                    )}
                  </CopyButton>
                  <GrayButton
                    leftIcon={<IconExternalLink />}
                    // @ts-ignore
                    target={'_blank'}
                    component="a"
                    href={linkToBot}
                  >
                    Ссылка на подключение
                  </GrayButton>
                  <GrayButton
                    onClick={() => {
                      setTelegramNotificationCodeToSite.mutate({
                        siteID: site.id,
                        del: true,
                      })
                    }}
                    leftIcon={<IconTrash color="red" />}
                    css={{
                      cursor: 'pointer',
                    }}
                  >
                    <Text color="red">Удалить ссылку</Text>
                  </GrayButton>
                </Group>
              ) : (
                <></>
              )}
            </SimpleGrid>
          </Group>
          {site.telegramNotifications.length ? (
            <>
              <Divider my={'md'} />
              <Group px="lg">
                <Text size={'sm'}>
                  {site.telegramNotifications.length ? 'Подключенные аккаунты' : 'Нет подключенных аккаунтов'}
                </Text>
                {site.telegramNotifications.length &&
                  site.telegramNotifications.map(telegramNotification => (
                    <TelegramUserSync
                      onClick={async ({ userID }) => {
                        setTelegramNotificationsToSite.mutate({
                          userID,
                          siteID: site.id,
                          type: 'disconnect',
                        })
                      }}
                      modelID={telegramNotification.id}
                      selectID={telegramNotification.selectID}
                    />
                  ))}
              </Group>
            </>
          ) : null}
        </SimpleGrid>
      </Paper>
    </>
  )
}
export function VKPixel() {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const siteDomain = useGetSelectDataUndefined({ modelID: site.domain?.id, selectID: site.domain?.selectID })
  const paidPlace = useGetSelectData({ modelID: site.paidPlace?.id, selectID: site.paidPlace?.selectID })
  const user = useGetSelectData({ modelID: site.userId, selectID: 'UserSelect1' })

  const updateFieldSite = trpc.useMutation('user.site.updateFieldSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })
  const cacheSite = trpc.useMutation('user.site.cacheSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })

  const updateFaviconSite = trpc.useMutation('user.site.updateFaviconSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })
  const updateTemplatePictureSite = trpc.useMutation('user.site.updateTemplatePictureSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })

  const updateDomainSite = trpc.useMutation('user.domain.updateDomainSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {
      setDomainsOpened(false)
    },
  })

  const createTarget = trpc.useMutation('user.yandexMetrika.createTarget', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteTarget = trpc.useMutation('user.yandexMetrika.deleteTarget', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [imagesOpened, setImagesOpened] = useState(false)
  const [domainsOpened, setDomainsOpened] = useState(false)

  const moveSiteToUser = trpc.useMutation('user.moveSiteToUser', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const moveSiteToUserForm = useForm({
    initialValues: {
      userLogin: '',
    },
  })
  const createTargetForm = useForm({
    initialValues: {
      name: '',
    },
  })

  const [imagesTemplateOpened, setImagesTemplateOpened] = useState(false)

  const [leadEmail, setLeadEmail] = useState(site.leadEmail ? site.leadEmail : '')

  useEffect(() => {
    setLeadEmail(site.leadEmail ? site.leadEmail : '')
  }, [site.leadEmail])

  const setTelegramNotificationsToSite = trpc.useMutation('user.setTelegramNotificationsToSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const setTelegramNotificationCodeToSite = trpc.useMutation('user.setTelegramNotificationCodeToSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const pinnTelegramUser = useForm({
    initialValues: {
      userName: '',
    },
  })

  return (
    <>
      <Paper p={'lg'}>
        <SimpleGrid spacing={12} cols={1}>
          <VKLogo />
          <TextInputWithUpdate
            value={`${site.vkontaktePixelId}`}
            onChange={value => {
              updateFieldSite.mutate({
                siteID,
                vkontaktePixelId: value,
              })
            }}
            placeholder={'VK-RTRG-0000000-хх00хх'}
            label={'Номер/id пикселя Вконтакте'}
          />
        </SimpleGrid>
      </Paper>
    </>
  )
}
export function Bitrix() {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const siteDomain = useGetSelectDataUndefined({ modelID: site.domain?.id, selectID: site.domain?.selectID })
  const paidPlace = useGetSelectData({ modelID: site.paidPlace?.id, selectID: site.paidPlace?.selectID })
  const user = useGetSelectData({ modelID: site.userId, selectID: 'UserSelect1' })

  const updateFieldSite = trpc.useMutation('user.site.updateFieldSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })
  const cacheSite = trpc.useMutation('user.site.cacheSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })

  const updateFaviconSite = trpc.useMutation('user.site.updateFaviconSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })
  const updateTemplatePictureSite = trpc.useMutation('user.site.updateTemplatePictureSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })

  const updateDomainSite = trpc.useMutation('user.domain.updateDomainSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {
      setDomainsOpened(false)
    },
  })

  const createTarget = trpc.useMutation('user.yandexMetrika.createTarget', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteTarget = trpc.useMutation('user.yandexMetrika.deleteTarget', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [imagesOpened, setImagesOpened] = useState(false)
  const [domainsOpened, setDomainsOpened] = useState(false)

  const moveSiteToUser = trpc.useMutation('user.moveSiteToUser', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const moveSiteToUserForm = useForm({
    initialValues: {
      userLogin: '',
    },
  })
  const createTargetForm = useForm({
    initialValues: {
      name: '',
    },
  })

  const [imagesTemplateOpened, setImagesTemplateOpened] = useState(false)

  const [leadEmail, setLeadEmail] = useState(site.leadEmail ? site.leadEmail : '')

  useEffect(() => {
    setLeadEmail(site.leadEmail ? site.leadEmail : '')
  }, [site.leadEmail])

  const setTelegramNotificationsToSite = trpc.useMutation('user.setTelegramNotificationsToSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const setTelegramNotificationCodeToSite = trpc.useMutation('user.setTelegramNotificationCodeToSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const pinnTelegramUser = useForm({
    initialValues: {
      userName: '',
    },
  })
  return (
    <>
      <Paper p={'lg'}>
        <SimpleGrid spacing={12} cols={1}>
          <img height={35} src={getPathToFile({ fileName: 'Bitrix24-logo-ru.svg', folderName: '000-service' })} />
          <Text size="xs">
            Отправка заявок в Битрикс24: перейти в "Приложения" {'->'} "Разработчикам" {'->'} "Интегрировать с внешними
            ситемами"
            {'->'} "Добавить лиды" {'->'} Затем оставить одно поле {'FIELDS[TITLE]'} из предложенных и удалить остальные
            {'->'} Затем скопировать из поля "Вебхук для вызова rest api" URL и вставить в поле ниже {'->'} Нажать "Сохранить" в
            конце страницы в Битрикс. Теперь заявки будут приходить в CRM
          </Text>
          <TextInputWithUpdate
            value={site.bitrixAddLeadURL}
            onChange={value => {
              updateFieldSite.mutate({
                siteID,
                bitrixAddLeadURL: value,
              })
            }}
            label={'Вебхук для вызова rest api'}
          />
        </SimpleGrid>
      </Paper>
    </>
  )
}
export function HeadCode() {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const siteDomain = useGetSelectDataUndefined({ modelID: site.domain?.id, selectID: site.domain?.selectID })
  const paidPlace = useGetSelectData({ modelID: site.paidPlace?.id, selectID: site.paidPlace?.selectID })
  const user = useGetSelectData({ modelID: site.userId, selectID: 'UserSelect1' })

  const updateFieldSite = trpc.useMutation('user.site.updateFieldSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })
  const cacheSite = trpc.useMutation('user.site.cacheSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })

  const updateFaviconSite = trpc.useMutation('user.site.updateFaviconSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })
  const updateTemplatePictureSite = trpc.useMutation('user.site.updateTemplatePictureSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })

  const updateDomainSite = trpc.useMutation('user.domain.updateDomainSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {
      setDomainsOpened(false)
    },
  })

  const createTarget = trpc.useMutation('user.yandexMetrika.createTarget', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteTarget = trpc.useMutation('user.yandexMetrika.deleteTarget', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [imagesOpened, setImagesOpened] = useState(false)
  const [domainsOpened, setDomainsOpened] = useState(false)

  const moveSiteToUser = trpc.useMutation('user.moveSiteToUser', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const moveSiteToUserForm = useForm({
    initialValues: {
      userLogin: '',
    },
  })
  const createTargetForm = useForm({
    initialValues: {
      name: '',
    },
  })

  const [imagesTemplateOpened, setImagesTemplateOpened] = useState(false)

  const [leadEmail, setLeadEmail] = useState(site.leadEmail ? site.leadEmail : '')

  useEffect(() => {
    setLeadEmail(site.leadEmail ? site.leadEmail : '')
  }, [site.leadEmail])

  const setTelegramNotificationsToSite = trpc.useMutation('user.setTelegramNotificationsToSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const setTelegramNotificationCodeToSite = trpc.useMutation('user.setTelegramNotificationCodeToSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const pinnTelegramUser = useForm({
    initialValues: {
      userName: '',
    },
  })

  return (
    <>
      <Paper p={'lg'}>
        <SimpleGrid>
          <TextInputWithUpdate
            textarea={true}
            value={site.headScript}
            autosize={true}
            onChange={value => {
              updateFieldSite.mutate({
                siteID,
                headScript: value,
              })
            }}
            label={'Вставить код перед закрытием тега <head>'}
          />
        </SimpleGrid>
      </Paper>
    </>
  )
}

export function BodyCode() {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const siteDomain = useGetSelectDataUndefined({ modelID: site.domain?.id, selectID: site.domain?.selectID })
  const paidPlace = useGetSelectData({ modelID: site.paidPlace?.id, selectID: site.paidPlace?.selectID })
  const user = useGetSelectData({ modelID: site.userId, selectID: 'UserSelect1' })

  const updateFieldSite = trpc.useMutation('user.site.updateFieldSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })
  const cacheSite = trpc.useMutation('user.site.cacheSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })

  const updateFaviconSite = trpc.useMutation('user.site.updateFaviconSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })
  const updateTemplatePictureSite = trpc.useMutation('user.site.updateTemplatePictureSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })

  const updateDomainSite = trpc.useMutation('user.domain.updateDomainSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {
      setDomainsOpened(false)
    },
  })

  const createTarget = trpc.useMutation('user.yandexMetrika.createTarget', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteTarget = trpc.useMutation('user.yandexMetrika.deleteTarget', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const [imagesOpened, setImagesOpened] = useState(false)
  const [domainsOpened, setDomainsOpened] = useState(false)

  const moveSiteToUser = trpc.useMutation('user.moveSiteToUser', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const moveSiteToUserForm = useForm({
    initialValues: {
      userLogin: '',
    },
  })
  const createTargetForm = useForm({
    initialValues: {
      name: '',
    },
  })

  const [imagesTemplateOpened, setImagesTemplateOpened] = useState(false)

  const [leadEmail, setLeadEmail] = useState(site.leadEmail ? site.leadEmail : '')

  useEffect(() => {
    setLeadEmail(site.leadEmail ? site.leadEmail : '')
  }, [site.leadEmail])

  const setTelegramNotificationsToSite = trpc.useMutation('user.setTelegramNotificationsToSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const setTelegramNotificationCodeToSite = trpc.useMutation('user.setTelegramNotificationCodeToSite', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const pinnTelegramUser = useForm({
    initialValues: {
      userName: '',
    },
  })

  return (
    <>
      <Paper p={'lg'}>
        <SimpleGrid>
          <TextInputWithUpdate
            textarea={true}
            value={site.customScript}
            autosize={true}
            onChange={value => {
              updateFieldSite.mutate({
                siteID,
                customScript: value,
              })
            }}
            label={'Вставить код перед закрытием тега <body>'}
          />
        </SimpleGrid>
      </Paper>
    </>
  )
}

const TelegramUserSync = ({
  modelID,
  selectID,
  onClick,
}: {
  modelID: string
  selectID: 'TelegramUser1'
  onClick: ({}: { userID: string }) => {}
}) => {
  const telegramUser = useGetSelectData({ modelID, selectID })
  return (
    <GrayButton
      onClick={() => {
        onClick({ userID: telegramUser.id })
      }}
      rightIcon={<IconX />}
      small={true}
    >
      @{telegramUser.userName}
      {telegramUser.firstName || telegramUser.lastName
        ? ` - (${telegramUser.firstName ? telegramUser.firstName : telegramUser.firstName}${
            telegramUser.lastName ? telegramUser.lastName : ` ${telegramUser.lastName}`
          })`
        : ''}
    </GrayButton>
  )
}

const VKLogo = () => (
  <Group spacing={6} css={{ alignContent: 'center' }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 192 192">
      <g fill="none" fill-rule="evenodd">
        <path
          fill="#5181B8"
          d="M66.56 0h58.88C179.2 0 192 12.8 192 66.56v58.88c0 53.76-12.8 66.56-66.56 66.56H66.56C12.8 192 0 179.2 0 125.44V66.56C0 12.8 12.8 0 66.56 0z"
        />
        <path
          fill="#FFF"
          d="M157.234 66.146c.89-2.966 0-5.146-4.234-5.146h-14c-3.56 0-5.2 1.883-6.09 3.96 0 0-7.12 17.353-17.206 28.625-3.263 3.263-4.746 4.301-6.526 4.301-.89 0-2.178-1.038-2.178-4.004V66.146c0-3.56-1.033-5.146-4-5.146H81c-2.224 0-3.562 1.652-3.562 3.218 0 3.375 5.042 4.153 5.562 13.645V98.48c0 4.52-.816 5.34-2.596 5.34-4.746 0-16.29-17.432-23.138-37.377C55.924 62.566 54.578 61 51 61H37c-4 0-4.8 1.883-4.8 3.96 0 3.708 4.747 22.1 22.1 46.424C65.869 127.995 82.168 137 97 137c8.9 0 10-2 10-5.445V119c0-4 .843-4.798 3.661-4.798 2.077 0 5.636 1.038 13.943 9.047C134.096 132.742 135.66 137 141 137h14c4 0 6-2 4.846-5.947-1.262-3.934-5.794-9.64-11.808-16.406-3.263-3.857-8.158-8.01-9.64-10.086-2.077-2.67-1.484-3.857 0-6.23 0 0 17.056-24.027 18.836-32.185z"
        />
      </g>
    </svg>
    <Text
      size={'xl'}
      css={{
        fontWeight: '700',
      }}
    >
      Пиксель
    </Text>
  </Group>
)

const YanderMetrikaLogo = () => (
  <svg width="130" height="28" viewBox="0 0 130 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="a"
      // style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="1"
      width="26"
      height="27"
    >
      <ellipse cx="13" cy="14.001" rx="13" ry="13" fill="#fff" />
    </mask>
    <g mask="url(#a)">
      <path fill="url(#b)" d="M0 1.002h26v26H0z" />
      <path d="M0 17h8v10H0V17z" fill="url(#c)" />
      <path d="M8 13.8c0-1.68 0-2.52.327-3.162a3 3 0 0 1 1.311-1.311C10.28 9 11.12 9 12.8 9H18v18H8V13.8z" fill="url(#d)" />
      <path d="M18 1h8v26h-8V1z" fill="url(#e)" />
    </g>
    <path
      d="M39.826 23.444h2.966l3.916-14.492v14.492h3.246V4H45.42l-3.944 14.576L37.504 4H33v19.444h2.91V8.951l3.916 14.492zm23.697-.952V19.78c-1.035.699-2.77 1.314-4.392 1.314-2.434 0-3.357-1.147-3.497-3.497h8.03v-1.762c0-4.896-2.155-6.742-5.484-6.742-4.056 0-5.987 3.105-5.987 7.357 0 4.896 2.406 7.274 6.659 7.274 2.126 0 3.692-.56 4.672-1.23zm-5.399-10.77c1.65 0 2.154 1.37 2.154 3.133v.28h-4.644c.084-2.239.895-3.414 2.49-3.414zm17.383.279v-2.63H64.344v2.63h3.917v11.443h3.33V12h3.916zm5.048-2.63h-3.077V27.5h3.329v-5.707c.84 1.259 2.07 1.93 3.497 1.93 3.245 0 5.483-2.602 5.483-7.33 0-4.7-2.182-7.301-5.315-7.301-1.567 0-2.854.727-3.749 2.098l-.168-1.819zm2.91 11.722c-1.763 0-2.658-1.426-2.658-4.672 0-3.273.951-4.7 2.825-4.7 1.819 0 2.714 1.427 2.714 4.672 0 3.274-.951 4.7-2.881 4.7zm8.547-11.722v14.072h2.966l5.091-8.7v8.7h3.218V9.372h-2.966l-5.091 8.701v-8.7h-3.218zm22.323 14.073h3.777l-5.343-7.47 4.7-6.603h-3.358l-4.7 6.603V9.37h-3.329v14.072h3.329v-6.798l4.924 6.799zm15.134-9.596c0-3.414-1.734-4.7-5.259-4.7-2.21 0-3.945.699-4.952 1.287v2.77c.895-.672 2.854-1.4 4.56-1.4 1.595 0 2.322.56 2.322 2.07v.784h-.531c-5.092 0-7.358 1.678-7.358 4.532s1.734 4.448 4.308 4.448c1.959 0 2.798-.643 3.441-1.315h.14c.028.364.14.84.252 1.12h3.245a35.39 35.39 0 0 1-.168-3.442v-6.154zm-3.329 6.238c-.42.616-1.203 1.12-2.378 1.12-1.399 0-2.098-.784-2.098-1.987 0-1.595 1.147-2.182 4.001-2.182h.475v3.05z"
      fill="#1B202B"
    />
    <defs>
      <linearGradient id="b" x1="18.633" y1="19.851" x2="-2.817" y2="-10.049" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4643B9" />
        <stop offset="1" stopColor="#1E8AFF" />
      </linearGradient>
      <linearGradient id="c" x1="8.527" y1="23.54" x2="-15.121" y2="19.421" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF002E" />
        <stop offset="1" stopColor="#FFADA1" />
      </linearGradient>
      <linearGradient id="d" x1="69.5" y1="66.5" x2="75.453" y2="17.813" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3C3BA0" />
        <stop offset=".49" stopColor="#1E8AFF" />
        <stop offset="1" stopColor="#00B2FF" />
      </linearGradient>
      <linearGradient id="e" x1="18" y1="2.337" x2="40.642" y2="14.037" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFEA1A" />
        <stop offset="1" stopColor="#FFB800" />
      </linearGradient>
    </defs>
  </svg>
)

export function WBot() {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const user = useGetSelectData({ modelID: site.userId, selectID: 'UserSelect1' })

  const createWBotClient = trpc.useMutation('user.wBot.createWBotClient', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })

  return (
    <>
      <Paper p={'lg'}>
        <SimpleGrid>
          {site.whatsAppBot ? (
            <>
              <WBotClient modelID={site.whatsAppBot.id} selectID={site.whatsAppBot.selectID} />
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  createWBotClient.mutate({
                    siteID: site.id,
                  })
                }}
              >
                Создать бота
              </Button>
            </>
          )}
        </SimpleGrid>
      </Paper>
    </>
  )
}

export function WBotClient({ modelID, selectID }: { modelID: string; selectID: 'WhatsAppBot1' }) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const user = useGetSelectData({ modelID: site.userId, selectID: 'UserSelect1' })
  const wBot = useGetSelectData({ modelID, selectID })

  const createWBotLink = trpc.useMutation('user.wBot.createWBotLink', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })

  const canvasRef = useRef<HTMLCanvasElement>(null)

  const canvasID = 'qr-canvas'

  useEffect(() => {
    if (wBot.qrCode && !wBot.connected) {
      var canvas = document.getElementById(canvasID)

      QRCode.toCanvas(canvas, wBot.qrCode, function (error) {
        if (error) console.error(error)
        console.log('success!')
      })
    }
  }, [wBot.connected, wBot.qrCode])

  return (
    <>
      <Paper p={'lg'}>
        <SimpleGrid>
          {wBot.connected ? (
            <>
              {wBot.phone ? (
                <>
                  <Group>
                    <Text>Бот подключен; Телефон: {wBot.phone}</Text>

                    <Button
                      onClick={() => {
                        const text = prompt('Ввведите текст ссылки')
                        if (text) {
                          createWBotLink.mutate({
                            wBotID: wBot.id,
                            text,
                          })
                        }
                      }}
                      loading={createWBotLink.isLoading}
                    >
                      Создать ссылку
                    </Button>
                  </Group>

                  {wBot.links.map(link => {
                    return <WBotClientLink key={link.id} modelID={link.id} selectID={link.selectID} />
                  })}
                </>
              ) : (
                <>
                  <Text>Бот без телефона;</Text>
                </>
              )}
            </>
          ) : (
            <canvas id={canvasID} ref={canvasRef}></canvas>
          )}
        </SimpleGrid>
      </Paper>
    </>
  )
}

export function WBotClientLink({ modelID, selectID }: { modelID: string; selectID: 'WhatsAppBotLink1' }) {
  const { siteID } = useSyncSiteContext()
  const site = useGetSelectData({ modelID: siteID, selectID: 'Site1' })
  const user = useGetSelectData({ modelID: site.userId, selectID: 'UserSelect1' })
  const wBotLink = useGetSelectData({ modelID, selectID })
  const updatedWBotLink = trpc.useMutation('user.wBot.updateWBotLink', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
    onSuccess: () => {},
  })

  return (
    <>
      <Paper withBorder p={'lg'}>
        <SimpleGrid>
          <Text>Текст: {wBotLink.text}</Text>{' '}
          <Button
            onClick={() => {
              const text = prompt('Ввведите текст ссылки')
              if (text) {
                updatedWBotLink.mutate({
                  wBotLinkID: wBotLink.id,
                  text,
                })
              }
            }}
          >
            обновить текст
          </Button>
          <ChooseTarget parentType="wBotLink" parentID={wBotLink.id} targetID={wBotLink.target?.id} />
        </SimpleGrid>
      </Paper>
    </>
  )
}
