import { NestedTableDataSelect } from 'server/selects'

const getOpenedPopups = () => {
  const openPopupIDsLocalStorage = localStorage.getItem('openPopupIDs')
  const openPopupIDs: string[] = openPopupIDsLocalStorage ? JSON.parse(openPopupIDsLocalStorage) : []

  return openPopupIDs
}

const addOpenedPopup = ({ popupID }: { popupID: string }) => {
  const openPopupIDs = getOpenedPopups()
  const popupIsOpened = openPopupIDs.find(id => id == popupID)
  if (!popupIsOpened) {
    openPopupIDs.push(popupID)
    localStorage.setItem('openPopupIDs', JSON.stringify(openPopupIDs))
  }
}

const PopupActiveCenter = () => {
  type PopupID = string
  type PopupData = string | null
  type CallbackArgsType = {
    popupID: PopupID
    data: PopupData
    fromScroll?: true
    isCenter?: boolean
    values?: NestedTableDataSelect['rows'][0]['items']
  }
  type CallbackType = ({}: CallbackArgsType) => void

  let listeners: Array<{
    callback: CallbackType
    id: string
  }> = []

  let activePopupID = ''
  let isActivePopupCenter: undefined | boolean = undefined

  return {
    subscribePopupID: ({ callback, popupID }: { callback: CallbackType; popupID: string }) => {
      const id = 'id' + Math.random().toString(16).slice(2)
      listeners.push({ callback, id })

      return {
        active: activePopupID == popupID,
        unlisten: () => {
          listeners = listeners.filter(listener => listener.id !== id)
        },
      }
    },
    closePopup: ({ popupID }: { popupID: string }) => {
      activePopupID = ''
      isActivePopupCenter = undefined
      addOpenedPopup({ popupID })
    },
    setPopupID: ({ popupID, data, fromScroll, isCenter, values }: CallbackArgsType) => {
      if (isActivePopupCenter && activePopupID && fromScroll) {
      } else {
        const callbackArgs = { popupID, data, fromScroll, values }

        if (fromScroll) {
          const openPopupIDs = getOpenedPopups()
          const popupIsOpened = openPopupIDs.find(id => id == popupID)

          if (!popupIsOpened) {
            // addOpenedPopup({ popupID })
            activePopupID = popupID
            isActivePopupCenter = isCenter

            listeners.map(listener => {
              listener.callback(callbackArgs)
            })
          }
        } else {
          activePopupID = popupID
          isActivePopupCenter = isCenter

          listeners.map(listener => {
            listener.callback(callbackArgs)
          })
        }
      }
    },
  }
}

export const popupActiveCenter = PopupActiveCenter()
